<template>
  <div>
    <div class="page-header">
      <h1>
        <span>{{ $t("reports") }} &ndash;</span>
        {{ $tc("device", 2) }}
      </h1>
    </div>

    <b-loading v-model="loading"></b-loading>

    <FilterAndSettings
      v-if="resultsFilter"
      :config="config"
      :results-filter="resultsFilter"
      :settings="settings"
    ></FilterAndSettings>

    <ResultsTable
      v-if="resultsFilter"
      :loading="loading"
      :config="config"
      :results-filter="resultsFilter"
      :settings="settings"
    ></ResultsTable>
  </div>
</template>

<script>
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { debounce, meanBy, sumBy } from "lodash-es";
import { mapActions } from "vuex";
import Currencies from "../../helpers/Currencies";
import DevicesConfig from "./config/DevicesConfig";
import FilterAndSettings from "./partials/FilterAndSettings";
import ResultsFilter from "./partials/ResultsFilter";
import ResultsTable from "./partials/ResultsTable";

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default {
  name: "ReportDevices",
  components: { FilterAndSettings, ResultsTable },
  data() {
    return {
      loading: true,
      config: new DevicesConfig(),
      devices: [],
      xViewMetadata: [],
      resultsFilter: null,
      settings: {
        currency: "EUR",
        conversionRate: Currencies.euroToDollarConversionRate,
      },
    };
  },
  watch: {
    "resultsFilter.filter.from"() {
      this.filterMeasurementsByDate();
    },
    "resultsFilter.filter.till"() {
      this.filterMeasurementsByDate();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAllDevices", "getAllMetadata"]),
    async load() {
      const devices = await this.getAllDevices();
      this.xViewMetadata = await this.getAllMetadata({
        bestScalingRun: true,
      });

      devices.forEach((d) => {
        d.device = d.serialNumber;
      });

      this.devices = devices;
      this.resultsFilter = new ResultsFilter(devices, false);

      this.filterMeasurementsByDate();
    },
    // Debounce function because both the filter watchers will trigger it at the same time on init
    filterMeasurementsByDate: debounce(function () {
      const results = [];

      const from = this.resultsFilter.filter.from;
      const till = this.resultsFilter.filter.till;

      this.devices.forEach((d) => {
        const measurements = this.xViewMetadata
          .filter((m) => m.serialNumber === d.serialNumber)
          .filter((m) => {
            if (from && till) {
              return dayjs(m.projectDate).isBetween(from, till, "day", "[]");
            }
            if (from) {
              return dayjs(m.projectDate).isSameOrAfter(from, "day");
            }
            if (till) {
              return dayjs(m.projectDate).isSameOrBefore(till, "day");
            }
            return true;
          });

        if (measurements.length === 0) {
          return;
        }

        d.totalNumberOfRunsAB = sumBy(measurements, "numberOfRunsAB");
        d.totalNumberOfRunsBA = sumBy(measurements, "numberOfRunsBA");
        d.totalNumberOfRuns = d.totalNumberOfRunsAB + d.totalNumberOfRunsBA;

        d.meanObservedSpreadPercentageXY = meanBy(
          measurements,
          "observedSpreadPercentageXY"
        );
        d.meanObservedSpreadPercentageZ = meanBy(
          measurements,
          "observedSpreadPercentageZ"
        );

        results.push(d);
      });

      this.resultsFilter.updateResults(results);

      this.loading = false;
    }),
  },
};
</script>
