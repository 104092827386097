import i18n from "../../../plugins/i18n";
import BaseConfig from "./BaseConfig";

export default class DevicesConfig extends BaseConfig {
  filterDate = true;

  columns = [
    {
      label: i18n.tc("device"),
      field: "device",
      component: "StringColumn",
    },
    {
      label: i18n.tc("product"),
      field: "product.name",
      component: "StringColumn",
    },
    {
      label: i18n.t("total_runs"),
      field: "totalNumberOfRunsBA",
      component: "RunsColumn",
    },
    {
      label: i18n.t("mean_spread_percentage"),
      field: "meanObservedSpreadPercentageXY",
      component: "MeanSpreadColumn",
      numeric: true,
    },
  ];
}
